export default [
  {
    title: 'Dashboard',
    route: 'dashboard-analytics',
    icon: 'ActivityIcon',
    unauth: false,
  },
  // {
  //   title: 'Identities',
  //   route: 'identities',
  //   icon: 'UserIcon',
  // },
  // {
  //   title: 'Applications',
  //   route: 'applications',
  //   icon: 'PackageIcon',
  // },
  {
    title: 'Action Items',
    route: 'actions',
    icon: 'CloudLightningIcon',
    unauth: false,
  },
  {
    title: 'Risks',
    route: 'risks',
    icon: 'AlertOctagonIcon',
    unauth: false,
    children: [
      {
        title: 'Identities',
        route: 'identities',
      },
      {
        title: 'Resources',
        route: 'resources',
      },
      {
        title: 'Configurations',
        route: 'configurations',
      },
    ],
  },
  {
    title: 'Scenarios',
    route: 'scenarios',
    icon: 'FastForwardIcon',
    unauth: false,
    children: [
      {
        title: 'Failed',
        route: 'scenarios-failed',
      },
      {
        title: 'All',
        route: 'scenarios',
      },
    ],
  },
  {
    title: 'Applications',
    route: 'integrations',
    icon: 'Link2Icon',
    unauth: true,
  },
  {
    title: 'Report',
    route: 'report',
    icon: 'DownloadIcon',
    unauth: false,
  },
  // {
  //   title: 'Risks',
  //   icon: 'CloudLightningIcon',
  //   children: [
  //     {
  //       title: 'Least Privileges',
  //       route: 'dashboard-ecommerce',
  //     },
  //     {
  //       title: 'Shadows',
  //       route: 'dashboard-analytics',
  //     },
  //   ],
  // },
  // {
  //   title: 'Flows',
  //   icon: 'ChevronsRightIcon',
  //   route: 'Flows',
  //   children: [
  //     {
  //       title: 'Offboarding',
  //       route: 'Offboarding',
  //     },
  //     {
  //       title: 'Policy Enforcement',
  //       route: 'dashboard-analytics',
  //     },
  //   ],
  // },
  // {
  //   title: 'Integrations',
  //   route: 'integrations',
  //   icon: 'LinkIcon',
  // },
  // {
  //   title: 'Dashboards',
  //   icon: 'HomeIcon',
  //   tag: '2',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'eCommerce',
  //       route: 'dashboard-ecommerce',
  //     },
  //     {
  //       title: 'Analytics',
  //       route: 'dashboard-analytics',
  //     },
  //   ],
  // },
]
