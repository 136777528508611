<template>
  <ul>
    <component
      v-for="item in showItems"
      :key="item.header || item.title"
      :item="item"
      :is="resolveNavItemComponent(item)"
    />

  </ul>
</template>

<script>
import store from '@/store'
import useApplicationsList from '@/views/app/api/useApplicationsList'
import applicationStoreModule from '@/views/app/api/applicationStoreModule'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hasLogins: true,
      showItems: this.items,
    }
  },
  methods: {
    toShow(item) {
      return item.unauth || this.hasLogins
    },
    initChecker(empty) {
      this.hasLogins = !empty
      this.showItems = this.items.filter(item => this.toShow(item))
    },
  },
  mounted() {
    this.fetchChecker(null, this.initChecker)
    setTimeout(() => {
      this.fetchChecker(null, this.initChecker)
    }, 10000)
  },
  setup() {
    provide('openGroups', ref([]))
    const USER_APP_STORE_MODULE_NAME = 'app-application'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, applicationStoreModule)

    const {
      fetchChecker,
    } = useApplicationsList()

    return {
      fetchChecker,
      resolveNavItemComponent,
    }
  },
}
</script>
