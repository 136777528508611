<template>
  <div class="navbar-container d-flex content align-items-center d-print-none">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <b-button variant="flat-primary" @click="back">Back</b-button>
      <b-button v-b-modal.expertModal class="float-right" variant="flat-danger">Need help? Schedule meeting</b-button>
      <b-modal
        hide-footer
        id="expertModal"
        size="lg">
        <vue-calendly url="https://calendly.com/saasment-experts/30min" :height="800"></vue-calendly>
      </b-modal>
      <bookmarks v-if="false" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale v-if="false" />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar v-if="false" />
      <cart-dropdown v-if="false" />
      <notification-dropdown v-if="false" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, BModal,
} from 'bootstrap-vue'
import router from '@/router'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    BModal,
    DarkToggler,
    CartDropdown,
    BButton,
    NotificationDropdown,
    UserDropdown,
  },
  methods: {
    back() {
      return router.go(-1)
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
